import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router";

const SignupForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    // userName: "blubein",
    // password: "blubein@123!"
    userName: "",
    password: ""
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name==="userName"){
      setFormData({
        userName: value,
        password: ""
      });
    }else{
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data:", formData);
    if (formData.userName === "" || formData.password === "") {
      alert("Both UserName and Password are required");
    } else {
      setLoading(true);
      axios
        .post(`https://nodeapi.smartdietplanner.com/api/tmsdp/verifyMail`, {
          domain: formData.userName,
          password: formData.password,
        })
        .then((res) => {
          console.log("Result >> ", res.data);
          if (res.data.valid) {
            navigate("home", { state: res.data.data });
          } else {
            alert("Incorrect Credentials");
          }
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
        setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="w-full h-full pt-2">
      <img src="https://fitrofy.com/wp-content/uploads/2023/04/mainlogo.webp" alt="logo" className=""/>
      <div className="flex flex-col justify-center items-center">
    <div className="flex justify-center items-center gap-10 border-2 border-black m-2 mb-10 pr-[75px]">
      <img src="https://fitrofy.com/wp-content/uploads/2023/06/healthyier1.webp" alt="SideImage" className="h-[30rem]" />
      <div className="flex flex-col justify-center items-center p-5 gap-10 border-2 border-black rounded-xl bg-white">
        <h1 className="text-xl font-semibold">Login</h1>
        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          <div>
          <div className="flex gap-2 mb-5">
            <label htmlFor="userName">Username:</label>
            <input
              type="text"
              id="userName"
              name="userName"
              value={formData.userName}
              placeholder="Enter your User Id"
              autoComplete="username"
              onChange={handleChange}
              className="border px-2"
            />
          </div>
          <div className="flex gap-2">
            <label htmlFor="password">Password:</label>
            <div className="flex gap-5 items-center">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Enter your Password"
                name="password"
                autoComplete="current-password"
                value={formData.password}
                onChange={handleChange}
                className="border px-2"
              />
              <span
                onClick={togglePasswordVisibility}
                className="cursor-pointer"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
          </div>
          <button
            type="submit"
            disabled={loading?true:false}
            className="mt-10 text-center text-xl font-medium border-2 bg-blue-300 rounded-full py-3 w-[60%]"
          >
            {loading?'Submitting...':'Submit'}
          </button>
        </form>
      </div>
    </div>
    </div>
    </div>
  );
};

export default SignupForm;
